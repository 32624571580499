import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import Lodging_Americinn from '../data/lodging-data/Lodging_AmericinnNorth.jpg'
import Lodging_Sheraton from '../data/lodging-data/Lodging_Sheraton.jpg'

class LodgingPage extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet>
                    <title>2024 CHCSD Lodging</title>
                    <meta name="description" content="2024 CHCSD Lodging Page" />
                </Helmet>

                <div id="main" className="alt">
                    <section id="one">
                        <div className="inner">
                            <header className="major">
                                <h1>Lodging</h1>
                                <h4>When requesting reservations you must mention Christian Homeschool Conference of South Dakota</h4>
                            </header>
                            <div className="tiles">
                                <article style={{backgroundImage: `url(${Lodging_Americinn})`}}>
                                    <header className="major">
                                        <h3><a href="http://www.wyndhamhotels.com/hotels/19264?corporate_id=1000058690" target="_blank">AmericInn Sioux Falls North</a></h3>
                                        <p><strong>Features:</strong> Hot Complimentary Breakfast, Complimentary Wireless High-Speed Internet, Pool, Hot tub, Fitness Center, Lounge</p>
                                        <p><a href="http://www.wyndhamhotels.com/hotels/19264?corporate_id=1000058690" target="_blank"><strong>Reservation Link</strong></a></p>
                                        <p><strong>Reservation Deadline:</strong> Until hotel is full</p>
                                    </header>
                                </article>
                                <article style={{backgroundImage: `url(${Lodging_Sheraton})`}}>
                                    <header className="major">
                                        <h3><a href="https://www.marriott.com/events/start.mi?id=1701288709957&key=GRP" target="_blank">Sheraton Sioux Falls & Convention Center</a></h3>
                                        <p><strong>Conference Rate:</strong> $149/night + tax </p>
                                        <p><strong>Features:</strong> Airport Shuttle, connected to the Sioux Falls Convention Center, Pool, Fitness Center, Restaurant, Bar, Wireless High-Speed Internet</p>
                                        <p><a href="https://www.marriott.com/events/start.mi?id=1701288709957&key=GRP" target="_blank"><strong>Reservation Link</strong></a></p>
                                        <p><i>If you encounter difficulty making a reservation with this link, please call the Sheraton directly at 605.331.0100.</i></p>
                                        <p><strong>Reservation cutoff:</strong> 04/11/2024</p>
                                    </header>
                                </article>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default LodgingPage
